<template>
<section>
  <div class="breadcrumb">
    <Breadcrumb :pages="pages"/>
  </div>
  <div class="mt-5">
    <p class="title">Nova propostas</p>  
  </div>
  <div>      
    <Steps class="mt-5 mb-5 steps">      
    </Steps>
  </div>  
</section>
   
</template>

<script>
import Steps from "./components/Steps.vue";
import Breadcrumb from "@/components/onpoint-breadcrumb/";
export default {
  components: {  
    Steps,
    Breadcrumb,
  },
  data(){
    return{
      pages: [
        { label: "Propostas", to: "/propostas" },
        { label: "Nova proposta" },
      ],     
    }
  },
  created(){    
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 700;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: rgba(75, 85, 99, 1);
}
</style>