var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [_c("Breadcrumb", { attrs: { pages: _vm.pages } })],
      1
    ),
    _vm._m(0),
    _c("div", [_c("Steps", { staticClass: "mt-5 mb-5 steps" })], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5" }, [
      _c("p", { staticClass: "title" }, [_vm._v("Nova propostas")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }