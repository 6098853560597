var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Steps", {
        staticClass: "mt-5 mb-5 steps",
        attrs: { model: _vm.steps },
      }),
      _c(
        "keep-alive",
        [
          _c("router-view", {
            attrs: { formData: _vm.formObject },
            on: {
              prevPage: function ($event) {
                return _vm.prevPage($event)
              },
              nextPage: function ($event) {
                return _vm.nextPage($event)
              },
              complete: _vm.complete,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }